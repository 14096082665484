import { parseISO, isValid } from 'date-fns';
import Moment from 'moment-timezone';
import { extendMoment } from '@nlevchuk/moment-range';

import { timeDiffInMinutes, setHoursAndMinutesForDate } from './momentUtils';
import { pluralize } from './pluralize';

const moment = extendMoment(Moment);

export const isDateValid = (date) => isValid(parseISO(date));

export const formattedDate = (date) => {
  const parsedDate = parseISO(date);
  return isValid(parsedDate) ? parsedDate : '';
};

export const formatDate = (datetime) => moment(datetime).format('ddd, D MMM YYYY');

export const formatTime = (datetime) => moment(datetime).format('hh:mm A');

export const getDate = (datetimeM) => datetimeM.format('YYYY-MM-DD');

export const getWeekday = (datetimeM) => datetimeM.format('dddd').toLowerCase();

const timezoneizeFunc = (timezone, fields) => (object) => {
  const timezoneFields = fields.reduce((acc, field) => {
    const tzField = moment.tz(object[field], timezone);

    return {
      ...acc,
      [`${field}Tz`]: tzField,
    };
  }, {});

  return {
    ...object,
    ...timezoneFields,
  };
};
export const timezoneizeObject = (object, timezone, ...fields) => {
  return timezoneizeFunc(timezone, fields)(object);
}
export const timezoneizeObjects = (objects, timezone, ...fields) => {
  return objects.map(timezoneizeFunc(timezone, fields));
}

export const getCurrentClientTime = () => moment().toISOString();

export const getCurrentBusinessTimeM = (timezone) => moment.tz(timezone);

export const prepareDurationsArray = () => {
  let startDayTime = moment().startOf('day');
  let endDayTime = moment().endOf('day');

  // This is a transition to winter or summer time. Test. Perhaps we need another library instead of Moment.
  const startOffset = moment().startOf('day').utcOffset()
  const endOffset = moment().endOf('day').utcOffset()
  const start = moment().startOf('day').format('Z')
  const end = moment().endOf('day').format('Z')
  const isTransitionTime = (startOffset !== endOffset) || (start !== end);
  if (isTransitionTime) {
    startDayTime = moment().add(3, 'day').startOf('day');
    endDayTime = moment().add(3, 'day').endOf('day');
  }

  const range = moment.range(startDayTime, endDayTime);
  const momentsArray = Array.from(range.by('minute', { step: 5 }));
  return momentsArray.reduce((acc, t) => {
    const time = {
      value: t.diff(startDayTime, 'minutes'),
      label: t.format('HH:mm'),
    };
    return [...acc, time];
  }, []);
};

export const prepareBusyTimesDurationsArray = (step = 5, timezone, busyTime) => {
  let startDayTime = moment().startOf('day');
  let endDayTime = moment().endOf('day');
  if (timezone) {
    startDayTime = moment.tz(timezone).startOf('day');
    endDayTime = moment.tz(timezone).endOf('day');
  }
  // This is a transition to winter or summer time. Test. Perhaps we need another library instead of Moment.
  const startOffset = moment().startOf('day').utcOffset()
  const endOffset = moment().endOf('day').utcOffset()
  const start = moment().startOf('day').format('Z')
  const end = moment().endOf('day').format('Z')
  const isTransitionTime = (startOffset !== endOffset) || (start !== end);
  if (isTransitionTime && !timezone) {
    startDayTime = moment().add(3, 'day').startOf('day');
    endDayTime = moment().add(3, 'day').endOf('day');
  } else if (isTransitionTime && timezone) {
    startDayTime = moment.tz(timezone).add(3, 'day').startOf('day');
    endDayTime = moment.tz(timezone).add(3, 'day').endOf('day');
  }

  const range = moment.range(startDayTime, endDayTime);
  const momentsArray = Array.from(range.by('minute', { step }));
  let maxMinutes = endDayTime.diff(startDayTime, 'minutes');
  if (busyTime && busyTime.time) {
    maxMinutes -= (busyTime.time)
  }
  return momentsArray
    .filter((t) => t.diff(startDayTime, 'minutes') <= maxMinutes)
    .reduce((acc, t) => {
      const time = {
        value: t.diff(startDayTime, 'minutes'),
        label: t.format('HH:mm'),
      };
      return [...acc, time];
    }, []);
};

export const prepareTimesArray = (step = 5, timezone) => {
  let startDayTime;
  let endDayTime;

  if (timezone) {
    startDayTime = moment.tz(timezone).startOf('day');
    endDayTime = moment.tz(timezone).endOf('day');
  } else {
    startDayTime = moment().startOf('day');
    endDayTime = moment().endOf('day');
  }

  // This is a transition to winter or summer time. Test. Perhaps we need another library instead of Moment.
  const startOffset = moment().startOf('day').utcOffset()
  const endOffset = moment().endOf('day').utcOffset()
  const start = moment().startOf('day').format('Z')
  const end = moment().endOf('day').format('Z')
  const isTransitionTime = (startOffset !== endOffset) || (start !== end);
  if (isTransitionTime && !timezone) {
    startDayTime = moment().add(3, 'day').startOf('day');
    endDayTime = moment().add(3, 'day').endOf('day');
  } else if (isTransitionTime && timezone) {
    startDayTime = moment.tz(timezone).add(3, 'day').startOf('day');
    endDayTime = moment.tz(timezone).add(3, 'day').endOf('day');
  }

  const range = moment.range(startDayTime, endDayTime);
  const momentsArray = Array.from(range.by('minute', { step }));
  return momentsArray.reduce((acc, t) => {
    const time = {
      value: t.diff(startDayTime, 'minutes'),
      label: t.format('h:mm a'),
    };
    return [...acc, time];
  }, []);
};

export const prepareBusyTimesArray = (step = 5, timezone, busyTime) => {
  let startDayTime;
  let endDayTime;

  if (timezone) {
    startDayTime = moment.tz(timezone).startOf('day');
    endDayTime = moment.tz(timezone).endOf('day');
  } else {
    startDayTime = moment().startOf('day');
    endDayTime = moment().endOf('day');
  }
  // This is a transition to winter or summer time. Test. Perhaps we need another library instead of Moment.
  const startOffset = moment().startOf('day').utcOffset()
  const endOffset = moment().endOf('day').utcOffset()
  const start = moment().startOf('day').format('Z')
  const end = moment().endOf('day').format('Z')
  const isTransitionTime = (startOffset !== endOffset) || (start !== end);
  if (isTransitionTime && !timezone) {
    startDayTime = moment().add(3, 'day').startOf('day');
    endDayTime = moment().add(3, 'day').endOf('day');
  } else if (isTransitionTime && timezone) {
    startDayTime = moment.tz(timezone).add(3, 'day').startOf('day');
    endDayTime = moment.tz(timezone).add(3, 'day').endOf('day');
  }
  if (busyTime && busyTime.duration) {
    endDayTime = endDayTime.subtract(busyTime.duration, 'minutes');
  }

  const range = moment.range(startDayTime, endDayTime);
  const momentsArray = Array.from(range.by('minute', { step }));
  return momentsArray
    .reduce((acc, t) => {
      const time = {
        value: t.diff(startDayTime, 'minutes'),
        label: t.format('h:mm a'),
      };
      return [...acc, time];
    }, []);
};

export const prepareIntervalsArray = (step = 10, maxMinutes = 240, text = 'Default calendar settings') => {
  const startDayTime = moment().startOf('day');
  const endDayTime = moment().endOf('day');
  const range = moment.range(startDayTime, endDayTime);

  const momentsArray = Array.from(range.by('minute', { step }));

  return momentsArray
    .filter((t) => t.diff(startDayTime, 'minutes') <= maxMinutes)
    .map((t) => {
      const value = t.diff(startDayTime, 'minutes');
      const label = value === 0 ? text : `${value} min`;
      const item = {
        value,
        label,
      }
      return item;
    });
};

export const prepareWeeksArray = () => {
  return Array.from(Array(52)).map((v, index) => {
    const value = index + 1;

    const title = pluralize(value, 'week');
    const label = `${value} ${title}`;

    return [value, label];
  })
};

export const dateHeaderFormat = (datetimeM) => {
  const weekday = datetimeM.format('ddd');
  const month = datetimeM.format('MMM');
  const date = datetimeM.format('D');

  return `${weekday}, ${month} ${date}`;
};

export const createDateMRange = (start, end) => {
  const range = moment.range(start, end);
  return Array.from(range.by('days'));
};

export const convertDateTimeIntoDateAndTime = (datetime, moment) => {
  const date = moment(datetime)
  const time = timeDiffInMinutes(date, { moment });

  return [date, time];
};

export const getStartAndEndTimeInBusinessTz = (options) => {
  const { date, time, duration, moment } = options;

  const selectedTime = moment(date);

  const beginningOfDay = moment(selectedTime).startOf('day');
  const startTime = setHoursAndMinutesForDate(beginningOfDay, time, { moment });
  const endTime = moment(startTime).add(duration, 'minutes');

  return [startTime, endTime];
};
